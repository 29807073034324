
export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'What We Do',
    headline: 'We help you scale any product in FMCG',
    description: 'Consult with us and get access to 35+ years of FMCG experience that will exponentially grow your business',
    buttonLabel: 'Tell Me More',
    imgStart: false,
    img: require('../../images/svg-2.svg').default,
    alt: 'business',
    dark: true,
    primary: true,
    darkText: false,
    to: 'services'
};

export const homeObjTwo = {
    id: 'services',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'What We Offer',
    headline: 'Expertise in several domains',
    description: 'Business Development, Exports, Branding, Key Account Management, P&L Management, Strategy & Budget Planning, Negotiation, Market Share Development, Market Plan Execution, New Market Development, Liaising, Revenue Growth, Training',
    buttonLabel: 'Get Started',
    imgStart: true,
    img: require('../../images/what-we-do.svg').default,
    alt: 'business',
    dark: false,
    primary: false,
    darkText: true,
    to: 'contact'
};

export const homeObjThree = {
    id: 'contact',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'How To Get Started',
    headline: 'Drop us an email. That\'s it!',
    description: 'Send an email to info@tsglobalfoods.com and a consultant will get in touch with you',
    buttonLabel: 'Home',
    imgStart: false,
    img: require('../../images/email.png').default,
    alt: 'business',
    dark: true,
    primary: true,
    darkText: false,
    to: 'home'
};