import React, { useState } from 'react'
import Video from '../../videos/TSG.mp4'
import { Button } from '../ButtonElement'
import {
    HeroContainer,
    HeroBg,
    VideoBg,
    HeroContent,
    HeroH1,
    HeroP,
    HeroBtnWrapper,
    ArrowForward,
    ArrowRight
} from './HeroElements'

const HeroSection = () => {
    const [hover, setHover] = useState(false)

    const onHover = () => {
        setHover(!hover);
    }
    return (
        <HeroContainer id="home">
            <HeroBg>
                <VideoBg autoPlay loop muted src={Video} type="video/mp4" />
            </HeroBg>
            {/* <HeroContent>
                <HeroH1>Want to catapult your business exponentially?</HeroH1>
                <HeroP>
                    Look no further... We've got you covered!
                </HeroP>
                <HeroBtnWrapper>
                    <Button to="about" onMouseEnter={onHover} onMouseLeave={onHover}
                        smooth={true}
                        duration={500}
                        primary="true"
                        dark="true">
                        Learn More {hover ? <ArrowForward /> : <ArrowRight />}
                    </Button>
                </HeroBtnWrapper>
            </HeroContent> */}
        </HeroContainer>
    )
}

export default HeroSection
